body{
  height: 100%;
  overflow: scroll;
}

@media (max-width: 40em) {
  .p-dataview .car-details,
  .p-dataview .search-icon,
  .p-dataview .car-images {
    text-align: center;
    margin-top: 0;
  }

  .p-dataview .filter-container {
    text-align: left;
  }
  .p-dataview-layout-options.p-buttonset > .p-button {
    margin-left: 0;
    display: inline-block;
  }
  .p-dataview-layout-options.p-buttonset > .p-button:first-child {
    border-radius: 50%;
  }
  .p-dataview-layout-options.p-buttonset > .p-button:last-child {
    border-radius: 50%;
  }
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
}

#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #337ab7;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
  cursor: default;
}

#react-paginate li {
  display: inline-block;
}

.p-sm-1,
.p-sm-2,
.p-sm-3,
.p-sm-4,
.p-sm-5,
.p-sm-6,
.p-sm-7,
.p-sm-8,
.p-sm-9,
.p-sm-10,
.p-sm-11,
.p-sm-12,
.p-md-1,
.p-md-2,
.p-md-3,
.p-md-4,
.p-md-5,
.p-md-6,
.p-md-7,
.p-md-8,
.p-md-9,
.p-md-10,
.p-md-11,
.p-md-12,
.p-lg-1,
.p-lg-2,
.p-lg-3,
.p-lg-4,
.p-lg-5,
.p-lg-6,
.p-lg-7,
.p-lg-8,
.p-lg-9,
.p-lg-10,
.p-lg-11,
.p-lg-12,
.p-xl-1,
.p-xl-2,
.p-xl-3,
.p-xl-4,
.p-xl-5,
.p-xl-6,
.p-xl-7,
.p-xl-8,
.p-xl-9,
.p-xl-10,
.p-xl-11,
.p-xl-12 {
  padding-bottom: 0px;
}






/* 공지팝업 */
.pop01_popup1 {position:absolute; z-index:1000000000; width:  300px;}
.pop01_popup2 {position:absolute; z-index:1000000000;}
.pop01_popup_wrap {float:left;z-index:1000000000;}
.pop01_popup_btn_wrap {float:right;z-index:1000000000;}
.pop01_popup_btn_wrap ul li {float:left; margin:0 0 0 5px;}
.pop01_popup_btn {float:right; background:#000000; min-width:60px; height:36px; line-height:40px; padding:0 15px 0 15px; text-align:center; display:inline-block; font-family:nanumgothic, sans-serif; color:#ffffff; font-size:12px; font-weight:600;}
.pop01_popup_box {float:left; width:  400px; height:   400px;  background:#000000; border:5px solid #000000;clear:both;z-index:1000000000;overflow-x: hidden;}
.pop01_popup_text {float:left; width:100%;z-index:1000000000;}
.pop01_popup_font1 {float:left; width:100%; font-family:'nanumsquare', sans-serif; font-size:22px; letter-spacing:-1px; font-weight:700; color:#000000; line-height:40px;}
.pop01_popup_font2 { width:100%; font-family:'nanumgothic', sans-serif; font-size:16px; letter-spacing:-1px; font-weight:400; color:#ffffff; line-height:28px;}




.datatable-style-demo .outofstock {
  font-weight: 700;
  color: #FF5252;
  text-decoration: line-through;
}

.datatable-style-demo .lowstock {
  font-weight: 700;
  color: #FFA726;
}

.datatable-style-demo .instock {
  font-weight: 700;
  color: #66BB6A;
}

.datatable-style-demo .row-level1 {
  background-color: #ffeb3b10  !important;
}

.datatable-style-demo .row-level2 {
  background-color: #8bc34a10  !important;
}
.datatable-style-demo .row-level3 {
  background-color: #2196f308 !important;
}

.datatable-style-demo .row-level4 {
  background-color: #fff !important;
}

._partner_div {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: red;
  margin-right: 1px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 16px;
  border-radius: 4px;
  padding-top:  1px;
}

._partner_div_2 {

  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 1px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 16px;
  border-radius: 4px;
  padding-top:  1px;
  background: #000;
}

._partner_div_3 {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 1px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 16px;
  border-radius: 4px;
  padding-top:  1px;

  background: rgb(255, 0, 0);
}

._partner_div_4 {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 1px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 16px;
  border-radius: 4px;
  padding-top:  1px;

  background: rgb(255, 114, 20);
}

._partner_div_5 {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: red;
  margin-right: 1px;
  color: #fff;
  text-align: center;
  font-size: 10px;
  line-height: 16px;
  border-radius: 4px;
  padding-top:  1px;

  
  background: rgb(211, 183, 1);
}



ul#gnb_real>li {
  position: relative;
  float: left;
  padding-left: 10px;
  background-color: #4d927b;
  border: 1px solid #26463a;
  margin-right: 4px;
  color: #ffffff;
  text-shadow: 0 0px 1px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  height: 24px;
  line-height: 24px;
  border-radius: 3px;
  /*box-shadow: 0 0px 2px rgba(0, 0, 0, 0.54);*/
}

ul#gnb_real>li:hover {
  background-color: #6ebea3;
  cursor: pointer;
}

ul#gnb_real>li>._alarm {
  background-color: #23463a;
  float: right;
  margin-left: 8px;
  width: 30px;
  height: 24px;
  border-radius: 3px;
  text-align: center;
}

ul#gnb_real>li._select {
  border-color: #f85303;
  background-color: #ff7b34;
}

ul#gnb_real>li._select>._alarm {
  background-color: #ae3f08;
}

ul#gnb_real>li>img {
  position: absolute;
  top: -18px;
  left: 4px;
  display: none;
}

ul#gnb_sts {
  position: relative;
  list-style: none;
  float: left;
  /* margin-top: -54px; */
  margin-left: 60px;
  overflow: hidden;
  z-index: 1;
}

ul#gnb_sts>li {
  float: left;
  border-right: 1px solid #80CBC4;
  width: auto;
  /* height: 46px;
  padding: 2px 10px; */
  color: #fff;
}

ul#gnb_sts>li:last-child {
  border-right-width: 0px;
}

ul#gnb_sts>li>ul {
  list-style: none;
  overflow: hidden;
}

ul#gnb_sts>li>ul>li {
  padding: 2px;
  text-align: center;
}

ul#gnb_sts>li>ul._4x>li {
  float: left;
  text-align: right;
  /* width: 68px; */
}

ul#gnb_sts>li>ul>li>._value {
  display: inline-block;
  text-align: right;
  width: 70px;
  padding: 1px 4px;
  background: #fff;
  color: #666;
  font-size: 11px;
  font-weight: bold;
  border: 1px solid #26463a;
  border-radius: 4px;
}

ul#gnb_sts>li>ul>li>._value._min {
  width: 30px;
}


._btn_point_to_cash {
  display: inline-block;
  padding: 0px 10px;
  height: 20px;
  line-height: 20px;
  background-color: #1565C0;
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  border-radius: 3px;
}

._btn_point_to_cash:hover {
  background-color: #1976D2; 
}

._btn_interception {
  display: inline-block;
  padding: 0px 10px 0px 10px;
  height: 20px;
  line-height: 20px;
  background-color: #ff1133;
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  border-radius: 3px;
}

._btn_interception2 {
  display: inline-block;
  padding: 0px 10px 0px 10px;
  height: 20px;
  line-height: 20px;
  background-color: #7811ff;
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  border-radius: 3px;
}

.element {
  width: 100%;
  height: 100%;
  border-radius: 9px;
  padding: 10px;
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    background-color: #49ff01;
  }
  100% {
    background-color: #FF4136;
  }
}

@keyframes pulse {
  0% {
    background-color: #49ff01;
  }
  100% {
    background-color: #FF4136;
  }
}





/* 공지팝업 */
.pop01_popup1 {position:absolute; z-index:1000000000;}
.pop01_popup2 {position:absolute; z-index:1000000000;}
.pop01_popup_wrap {float:left;z-index:1000000000;}
.pop01_popup_btn_wrap {float:right;z-index:1000000000;}
.pop01_popup_btn_wrap ul li {float:left; margin:0 0 0 5px;}
.pop01_popup_btn {float:right; background:#4caf50; min-width:60px; height:36px; line-height:40px; padding:0 15px 0 15px; text-align:center; display:inline-block; font-family:nanumgothic, sans-serif; color:#ffffff; font-size:12px; font-weight:600;}
.pop01_popup_box {float:left; background:#000000; border:5px solid #4caf50;clear:both;z-index:1000000000;}
.pop01_popup_text {float:left; width:100%;z-index:1000000000;}
.pop01_popup_font1 {float:left; width:100%; font-family:'nanumsquare', sans-serif; font-size:22px; letter-spacing:-1px; font-weight:700; color:#4caf50; line-height:40px;}
.pop01_popup_font2 {float:left; width:100%; font-family:'nanumgothic', sans-serif; font-size:16px; letter-spacing:-1px; font-weight:400; color:#ffffff; line-height:28px;}



